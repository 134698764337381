import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { LINK } from "store/Queries/Link";
import ResolveField from "components/Layout/ResolveField";
import Layout from "components/Layout";
import NoMatch from "components/404";
import Loading from "components/Loading";
import Header from "components/Layout/Header";
export default function Content({ slug, id }) {
  const { data, loading, error } = useQuery(LINK.getLinkBySlug, {
    variables: { slug, id },
  });

  if (loading) return <Loading />;
  if (!data?.getLinkBySlug || error) return <NoMatch />;
  if (!data.getLinkBySlug.masterPage) return "Ön Sayfa Ayarlanmamış";

  const ThemeComponent = React.lazy(() =>
    import(
      `../../Themes/${data.getLinkBySlug.masterPage.theme.folder}/index.js`
    )
  );

  return (
    <React.Suspense fallback={Loading}>
      <Layout>
        <Header
          setting={{
            metaTitle: data?.getLinkBySlug?.metaTitle,
            metaDescription: data?.getLinkBySlug?.metaDescription,
            metaKeywords: data?.getLinkBySlug?.metaKeywords,
          }}
        />

        <ThemeComponent
          content={data}
          ResolveField={ResolveField}
          fields={data.getLinkBySlug.masterPage.fields.reduce((field, item) => {
            field[item.name] = item;
            return field;
          }, {})}
        />
      </Layout>
    </React.Suspense>
  );
}
