import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { MASTER_PAGE } from "../../store/Queries/MasterPage";
import ResolveField from "../../components/Layout/ResolveField";
import Layout from "../../components/Layout";
import Loading from "components/Loading";
import Header from "components/Layout/Header";

export default function Home() {
  const { data, error, loading } = useQuery(MASTER_PAGE.getMasterPage);
  const [ploading, setPloading] = useState(true);

  useEffect(() => {
    if (loading === false) {
      setTimeout(() => {
        setPloading(false);
      }, 850);
    }
  }, [loading]);

  if (loading) return <Loading />;
  if (!data?.getMasterPage) return "Ön Sayfa Ayarlanmamış";

  const ThemeComponent = React.lazy(() =>
    import(`Themes/${data?.getMasterPage?.theme?.folder}/index.js`)
  );

  return (
    <>
      {ploading ? <Loading /> : null}
      <React.Suspense fallback={Loading}>
        <Layout>
          <Header setting={null} />
          <ThemeComponent
            ResolveField={ResolveField}
            fields={data?.getMasterPage?.fields?.reduce((field, item) => {
              field[item.name] = item;
              return field;
            }, {})}
          />
        </Layout>
      </React.Suspense>
    </>
  );
}
